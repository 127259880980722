import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import AppURL from '../../api/AppURL';
import axios from 'axios'
 class HomeSlider extends Component {
  constructor() {
    super();
    this.state = {
      // Initial state with empty values
      slider_title: '',
      slider_description: '',
      slider_image: '', // Assume image data is a URL or base64 string
      isLoading: true, // Display a loading indicator initially
    };
  }

  componentDidMount() {
    axios.get(AppURL.AllSlider)
      .then((response) => {
        if (response.status === 200) {
          const { slider_title, slider_description, slider_image } = response.data[0];
          this.setState({
            slider_title,
            slider_description,
            imageData: slider_image, // Assuming image data is present in the response
            isLoading: false,
          });
        } else {
          console.error('Error fetching site info:', response.statusText);
          // Handle errors gracefully, e.g., display an error message
        }
      })
      .catch((error) => {
        console.error('Error fetching site info:', error);
        // Handle errors gracefully, e.g., display an error message
      });
  }


  render() {
    const { isLoading, imageData, ...otherData } = this.state; // Destructure state for cleaner rendering
    return (
      <Fragment>
        <div className="banner-area banner-area-1 bg-gray">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-5 col-md-8 order-lg-12 align-self-center">
                    <div className="thumb b-animate-thumb">
                    <img src={imageData}   alt={imageData} />
                        
                    </div>
                  
                </div>
               
                
                <div className="col-lg-7 order-lg-1 align-self-center">
                    <div className="banner-inner text-center text-lg-left mt-5 mt-lg-0">
                        <h6 className="b-animate-1 sub-title">{otherData.slider_title}</h6>
                        <h1 className="b-animate-2 title">{otherData.slider_description}</h1>
                        <Link className="btn btn-base b-animate-3 mr-sm-3 mr-2" to="/contact">Get A Quote</Link>
                        <a className="btn btn-border-black b-animate-3" href="blog.html">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </Fragment>
    )
  }
}

export default HomeSlider