import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import Plan from '../components/others/Plan'
 class PlanPage extends Component {
  render() {
    return (
      <Fragment>
        <Navbar/>
       <Plan/>
        <MainFooter/>
      </Fragment>
    )
  }
}

export default PlanPage