import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import Contact from '../components/common/Contact'
 class ContactPage extends Component {
  render() {
    return (
      <Fragment>
      <Navbar/>
       <Contact/>
      <MainFooter/>
      </Fragment>
    )
  }
}

export default ContactPage