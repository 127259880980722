import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import Event from '../components/others/Event'
 class EventPage extends Component {
  render() {
    return (
     <Fragment>
         <Navbar/>
        <Event/>
        <MainFooter/>
     </Fragment>
    )
  }
}

export default EventPage