

 class AppURL   {
  static BaseURL="https://api.kbpin.com/api"
  static VisitorDetails = this.BaseURL+"/getVisitor";
  static PostContact = this.BaseURL+"/postcontact";
  static AllSiteInfo = this.BaseURL+"/allsiteinfo";
  static AllSlider = this.BaseURL+"/allslider";
}

export default AppURL