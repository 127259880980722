import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import HomeSlider from '../components/home/HomeSlider'
import IntroSection from '../components/home/IntroSection'
import AboutArea from '../components/home/AboutArea'
import CourseArea from '../components/home/CourseArea'
import WorkArea from '../components/home/WorkArea'
import TestimonialSection from '../components/home/TestimonialSection'
import TeamArea from '../components/home/TeamArea'
import ContactArea from '../components/home/ContactArea'
import AppURL from '../api/AppURL'
import axios from 'axios'

 class HomePage extends Component {

componentDidMount(){
  this.GetVisitorDetails();
}

  GetVisitorDetails =()=>{
    axios.get(AppURL.VisitorDetails).then().catch()
  }
  render() {
    return (
      <Fragment>
      <Navbar/>
        <HomeSlider/>
        <IntroSection/>
        <AboutArea/>
        <CourseArea/>
        <WorkArea/>
        <TestimonialSection/>
        <TeamArea/>
        <ContactArea/>
      <MainFooter/>
      </Fragment>
    )
  }
}

export default HomePage