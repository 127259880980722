import React, { Component, Fragment } from 'react'

 class CourseArea extends Component {
  render() {
    return (
     <Fragment>
         <div className="course-area pd-top-100 pd-bottom-90">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 col-md-11">
                    <div className="section-title style-white text-center">
                        <h2 className="title">Top Featured Courses</h2>
                    </div>
                </div>
            </div>
            <div className="edmt-nav-tab style-white text-center">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Graphics & Design</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Digital Marketing</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Writing & Translation</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="tab4-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">Music & Audio</a>
                    </li>
                </ul>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/1.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/1.png" alt="img"/></span>
                                            <span className="align-self-center">Nancy Reyes</span>
                                        </div>
                                        <h6><a href="course-details.html">Fox nymphs grab quick-jived waltz. Brick quiz whangs</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/2.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/2.png" alt="img"/></span>
                                            <span className="align-self-center">Joe Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Aenean sed nibh a magna posuere tempo faucib</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/3.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/3.png" alt="img"/></span>
                                            <span className="align-self-center">Timothy Willis</span>
                                        </div>
                                        <h6><a href="course-details.html">Praesent eu dolor eu orci vehicula euismod</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.9
                                                    <span>(73)</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="price text-right">
                                                    Price: <span>$74.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/4.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/4.png" alt="img"/></span>
                                            <span className="align-self-center">Walter Griffin</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis vestibulum elit vel neque pharetra vulputate</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.8
                                                    <span>(53)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$64.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/5.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/5.png" alt="img"/></span>
                                            <span className="align-self-center">Aaron Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Quisque suscipit ipsum est, eu venenatis leo ornare eget</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.5
                                                    <span>(21)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$34.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/6.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/6.png" alt="img"/></span>
                                            <span className="align-self-center">Randy Hart</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis rhoncus dui venenatis consequat porttito</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.4
                                                    <span>(20)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$55.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/3.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/3.png" alt="img"/></span>
                                            <span className="align-self-center">Timothy Willis</span>
                                        </div>
                                        <h6><a href="course-details.html">Praesent eu dolor eu orci vehicula euismod</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.9
                                                    <span>(73)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$74.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/4.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/4.png" alt="img"/></span>
                                            <span className="align-self-center">Walter Griffin</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis vestibulum elit vel neque pharetra vulputate</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.8
                                                    <span>(53)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$64.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/5.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/5.png" alt="img"/></span>
                                            <span className="align-self-center">Aaron Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Quisque suscipit ipsum est, eu venenatis leo ornare eget</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.5
                                                    <span>(21)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$34.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/6.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/6.png" alt="img"/></span>
                                            <span className="align-self-center">Randy Hart</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis rhoncus dui venenatis consequat porttito</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.4
                                                    <span>(20)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$55.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/1.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/1.png" alt="img"/></span>
                                            <span className="align-self-center">Nancy Reyes</span>
                                        </div>
                                        <h6><a href="course-details.html">Fox nymphs grab quick-jived waltz. Brick quiz whangs</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/2.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/2.png" alt="img"/></span>
                                            <span className="align-self-center">Joe Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Aenean sed nibh a magna posuere tempo faucib</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/4.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/4.png" alt="img"/></span>
                                            <span className="align-self-center">Walter Griffin</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis vestibulum elit vel neque pharetra vulputate</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.8
                                                    <span>(53)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$64.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/1.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/1.png" alt="img"/></span>
                                            <span className="align-self-center">Nancy Reyes</span>
                                        </div>
                                        <h6><a href="course-details.html">Fox nymphs grab quick-jived waltz. Brick quiz whangs</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/5.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/5.png" alt="img"/></span>
                                            <span className="align-self-center">Aaron Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Quisque suscipit ipsum est, eu venenatis leo ornare eget</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.5
                                                    <span>(21)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$34.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/2.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/2.png" alt="img"/></span>
                                            <span className="align-self-center">Joe Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Aenean sed nibh a magna posuere tempo faucib</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/3.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/3.png" alt="img"/></span>
                                            <span className="align-self-center">Timothy Willis</span>
                                        </div>
                                        <h6><a href="course-details.html">Praesent eu dolor eu orci vehicula euismod</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.9
                                                    <span>(73)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$74.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/6.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/6.png" alt="img"/></span>
                                            <span className="align-self-center">Randy Hart</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis rhoncus dui venenatis consequat porttito</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.4
                                                    <span>(20)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$55.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/3.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/3.png" alt="img"/></span>
                                            <span className="align-self-center">Timothy Willis</span>
                                        </div>
                                        <h6><a href="course-details.html">Praesent eu dolor eu orci vehicula euismod</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.9
                                                    <span>(73)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$74.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/6.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/6.png" alt="img"/></span>
                                            <span className="align-self-center">Randy Hart</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis rhoncus dui venenatis consequat porttito</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.4
                                                    <span>(20)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$55.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/4.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/4.png" alt="img"/></span>
                                            <span className="align-self-center">Walter Griffin</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis vestibulum elit vel neque pharetra vulputate</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.8
                                                    <span>(53)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$64.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/1.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/1.png" alt="img"/></span>
                                            <span className="align-self-center">Nancy Reyes</span>
                                        </div>
                                        <h6><a href="course-details.html">Fox nymphs grab quick-jived waltz. Brick quiz whangs</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/5.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/5.png" alt="img"/></span>
                                            <span className="align-self-center">Aaron Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Quisque suscipit ipsum est, eu venenatis leo ornare eget</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.5
                                                    <span>(21)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$34.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/2.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/2.png" alt="img"/></span>
                                            <span className="align-self-center">Joe Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Aenean sed nibh a magna posuere tempo faucib</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     </Fragment>
    )
  }
}

export default CourseArea