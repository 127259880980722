import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import Refund from '../components/others/Refund'

 class RefundPage extends Component {
  render() {
    return (
        <Fragment>
        <Navbar/>
        <Refund/>
        <MainFooter/>
      </Fragment>
    )
  }
}

export default RefundPage