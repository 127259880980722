import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import Register from '../components/common/Register'
 class RegisterPage extends Component {
  render() {
    return (
        <Fragment>
        <Navbar/>
        <Register/>
        <MainFooter/>
        </Fragment>
    )
  }
}

export default RegisterPage