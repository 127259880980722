import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import CourseDetail from '../components/course/CourseDetail'

 class CourseDetailPage extends Component {
  render() {
    return (
       <Fragment>
        <Navbar/>
       <CourseDetail/>
        <MainFooter/>
        </Fragment>
   
    )
  }
}

export default CourseDetailPage