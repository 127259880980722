import React, { Component, Fragment } from 'react'
import AppURL from '../../api/AppURL';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export class Refund extends Component {
    constructor(){
        super();
        this.state={
             refund:"",
             loaderDiv:"",
             mainDiv:"d-none"
        }
   }
   componentDidMount(){
    // axios.get(AppURL.AllSiteInfo).then(response =>{
    //      let StatusCode = response.status;
    //      if(StatusCode===200){
    //           let JsonData = (response.data)[0]['refund'];
    //           this.setState({refund:JsonData});
    //      } 

    // }).catch(error=>{

    // });
    let SiteInfoPurchase = sessionStorage.getItem("AllSiteInfo");
    if(SiteInfoPurchase == null){

        axios.get(AppURL.AllSiteInfo).then(response =>{
             let StatusCode = response.status;
             if(StatusCode==200){
                  let JsonData = (response.data)[0]['refund'];
                  this.setState({refund:JsonData,loaderDiv:"d-none",mainDiv:""});

                  sessionStorage.setItem("SiteInfoPurchase",JsonData)
             } 
             else{
                  toast.error("Somthing Went Wrong",{
                       position: "bottom-center"
                  });
             }


   }).catch(error=>{
        toast.error("Somthing Went Wrong",{
             position: "bottom-center"
        });
   });

}  // end If Conditon 
else{
   this.setState({refund:SiteInfoPurchase});
}

}
  render() {
    return (
     <Fragment>
         <div className="breadcrumb-area bg-overlay" style={{backgroundImage: 'url(assets/img/bg/3.png)'}}>
        <div className="container">
            <div className="breadcrumb-inner">
                <div className="section-title mb-0 text-center">
                    <h2 className="page-title">Refund</h2>
                    <ul className="page-list">
                        <li><a href="index.html">Home</a></li>
                        <li>Refund</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
     <div className="event-area pd-top-120 pd-bottom-120">


     <div className={this.state.loaderDiv}>

               <div class="ph-item">
    <div class="ph-col-12">        
        <div class="ph-row">           
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-6"></div>
            <div class="ph-col-6 empty"></div>
            <div class="ph-col-12"></div>
            <div class="ph-col-12"></div>
            <div class="ph-col-12"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>
</div>

</div>

<div className={this.state.mainDiv}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                 { ReactHtmlParser(this.state.refund) } 
                    {/* <div className="media single-event-inner">
                        <div className="media-left date">
                            <span>JAN</span><br/>
                            12
                        </div>
                        <div className="media-body details">
                            <ul className="blog-meta">
                                <li><i className="fa fa-clock-o"></i> 12:00 AM To 11:59 PM</li>
                                <li><i className="fa fa-map-marker"></i> Melbourne, Australia</li>
                            </ul>
                            <h5><a href="event-details.html">The quick, brown fox jumps over a lazy by when MTV ax</a>
                            </h5>
                            <p>Fox nymphs grab quick-jived waltz. Brick quiz whangs jumpy veldt fox. Bright vixens jump;
                                dozy fowl quack. Quick wafting zephyrs vex</p>
                        </div>
                    </div> */}
                    
                </div>
                
            </div>
        </div>
    </div>
    </div>
    <ToastContainer />
     </Fragment>
    )
  }
}

export default Refund