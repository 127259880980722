import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import Course from '../components/course/Course'

 class CoursePage extends Component {
  render() {
    return (
        <Fragment>
        <Navbar/>
        <Course/>
        <MainFooter/>
      </Fragment>
    )
  }
}

export default CoursePage