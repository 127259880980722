import React, { Component, Fragment } from 'react'

 class TeamArea extends Component {
  render() {
    return (
      <Fragment>
         <div className="team-area pd-top-110 pd-bottom-90">
        <div className="container-fluid pl-4 pr-4">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7">
                    <div className="section-title text-center">
                        <h6 className="sub-title double-line">Meet Our Team</h6>
                        <h2 className="title">Our Creative Team</h2>
                    </div>
                </div>
            </div>
            <div className="team-slider owl-carousel">
                <div className="item">
                    <div className="single-team-inner">
                        <div className="thumb">
                            <img src="assets/img/team/1.png" alt="img"/>
                            <div className="social-wrap">  
                                <div className="social-wrap-inner">
                                    <a className="social-share" href="#"><i className="fa fa-share-alt"></i></a>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="details"> 
                            <h4><a href="team-details.html">Doris Jordan</a></h4>
                            <span>Design Expert</span>
                        </div>  
                    </div>
                </div>
                <div className="item">
                    <div className="single-team-inner">
                        <div className="thumb">
                            <img src="assets/img/team/2.png" alt="img"/>
                            <div className="social-wrap">  
                                <div className="social-wrap-inner">
                                    <a className="social-share" href="#"><i className="fa fa-share-alt"></i></a>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="details"> 
                            <h4><a href="team-details.html">Eugen Freman</a></h4>
                            <span>Executive</span>
                        </div>  
                    </div>
                </div>
                <div className="item">
                    <div className="single-team-inner">
                        <div className="thumb">
                            <img src="assets/img/team/3.png" alt="img"/>
                            <div className="social-wrap">  
                                <div className="social-wrap-inner">
                                    <a className="social-share" href="#"><i className="fa fa-share-alt"></i></a>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="details"> 
                            <h4><a href="team-details.html">Jaction Leo</a></h4>
                            <span>Developer</span>
                        </div>  
                    </div>
                </div>
                <div className="item">
                    <div className="single-team-inner">
                        <div className="thumb">
                            <img src="assets/img/team/4.png" alt="img"/>
                            <div className="social-wrap">  
                                <div className="social-wrap-inner">
                                    <a className="social-share" href="#"><i className="fa fa-share-alt"></i></a>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="details"> 
                            <h4><a href="team-details.html">Jordan Feg</a></h4>
                            <span>Marketing Expert</span>
                        </div>  
                    </div>
                </div>
                <div className="item">
                    <div className="single-team-inner">
                        <div className="thumb">
                            <img src="assets/img/team/5.png" alt="img"/>
                            <div className="social-wrap">  
                                <div className="social-wrap-inner">
                                    <a className="social-share" href="#"><i className="fa fa-share-alt"></i></a>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="details"> 
                            <h4><a href="team-details.html">Walson Jordan</a></h4>
                            <span>Ui/Ux Expert</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
      </Fragment>
    )
  }
}

export default TeamArea