import React, { Component, Fragment } from 'react'
import validation from '../../validation/validation';
import axios from 'axios';
import AppURL from '../../api/AppURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 class Contact extends Component {
    constructor(){
        super();
        this.state={
            first_name:"",
            last_name:"",
            email:"",
            subject:"",
            message:""
        }
    }
    nameOnChange=(event)=>{
        let name = event.target.value;
        //alert(name);
        this.setState({first_name:name})
    }
    lastOnChange=(event)=>{
        let lastname = event.target.value;
        //alert(lastname);
        this.setState({last_name:lastname})
    }
    emailOnChange=(event)=>{
        let email = event.target.value;
        //alert(email);
        this.setState({email:email})
    }
    subjectOnChange=(event)=>{
        let subject = event.target.value;
        //alert(subject);
        this.setState({subject:subject})
    }
    messageOnChange=(event)=>{
        let message = event.target.value;
        //alert(message);
        this.setState({message:message})
    }
    onFormSubmit=(event)=>{
    //alert("helo");
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let email = this.state.email;
    let subject = this.state.subject;
    let message = this.state.message;
    let sendBtn = document.getElementById('sendBtn');
    let contactForm = document.getElementById('contactForm');

    if(message.length===0){
        toast.error("Please write your message");
    }
    else if(first_name.length===0){
        toast.error("Please write down our name");
        //alert("First Name field required")
    }
    else if(email.length===0){
        toast.error("Please write down our Email");
       // alert("email field required")
    }
    else if(subject.length===0){
        toast.error("Please write down our Subject");
        //alert("Subject field required")
    }
    else if(!(validation.NameRegx).test(first_name)){
       // alert("Invalid Name");
       toast.error("Invalid Name");
    }else{
        sendBtn.innerHTML="Sending.....";
        let MyFormData = new FormData();
        MyFormData.append("first_name",first_name)
        MyFormData.append("last_name",last_name)
        MyFormData.append("email",email)
        MyFormData.append("subject",subject)
        MyFormData.append("message",message)

        axios.post(AppURL.PostContact,MyFormData)
        .then(function(response){
        if(response.status===200 && response.data===1){
            //alert("Message send successfully!");
            toast.success("Message Send Successfully");
            sendBtn.innerHTML="Send Message";
            contactForm.reset();
        }else{
            toast.error("error"); 
          //  alert("error");
            sendBtn.innerHTML="Send Message";
        }
        })
        .catch(function(error){
            toast.error(error); 
            sendBtn.innerHTML="Send Message";
        })
    }
    event.preventDefault();
    }
  render() {
    return (
     <Fragment>
          <div className="breadcrumb-area bg-overlay" style={{backgroundImage: 'url(assets/img/bg/3.png)'}}>
        <div className="container">
            <div className="breadcrumb-inner">
                <div className="section-title mb-0 text-center">
                    <h2 className="page-title">Contact</h2>
                    <ul className="page-list">
                        <li><a href="index.html">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div className="contact-list pd-top-120 pd-bottom-90">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-4">
                    <div className="contact-list-inner">
                        <div className="media">
                            <div className="media-left">
                                <img src="assets/img/icon/17.png" alt="img"/>
                            </div>
                            <div className="media-body align-self-center">
                                <h5>Our Phone</h5>
                                <p>000 2324 39493</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="contact-list-inner">
                        <div className="media">
                            <div className="media-left">
                                <img src="assets/img/icon/18.png" alt="img"/>
                            </div>
                            <div className="media-body align-self-center">
                                <h5>Our Email</h5>
                                <p>name@website.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="contact-list-inner">
                        <div className="media">
                            <div className="media-left">
                                <img src="assets/img/icon/16.png" alt="img"/>
                            </div>
                            <div className="media-body align-self-center">
                                <h5>Our Address</h5>
                                <p>2 St, Loskia, amukara.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="counter-area pd-bottom-120">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <div className="section-title mb-0">
                        <h6 className="sub-title right-line">Get in touch</h6>
                        <h2 className="title">Write Us a Message</h2>
                        <p className="content pb-3">The quick, brown fox jumps over a lazy dog. DJs flock by when MTV ax
                            quiz prog. Junk MTV quiz graced by fox whelps. Bawds jog, </p>
                        <ul className="social-media style-base pt-3">
                            <li>
                                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-8 mt-5 mt-lg-0">
                    <form id="contactForm" onSubmit={this.onFormSubmit} className="contact-form-inner  mt-5 mt-md-0">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="single-input-inner style-bg-border">
                                    <input onChange={this.nameOnChange} type="text" placeholder="First Name"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="single-input-inner style-bg-border">
                                    <input onChange={this.lastOnChange} type="text" placeholder="Last Name"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="single-input-inner style-bg-border">
                                    <input onChange={this.emailOnChange} type="text" placeholder="Email"/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="single-input-inner style-bg-border">
                                    <input onChange={this.subjectOnChange} type="text" placeholder="Subject"/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="single-input-inner style-bg-border">
                                    <textarea onChange={this.messageOnChange} placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <button id='sendBtn' type='submit' className="btn btn-base">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ToastContainer />
     </Fragment>
    )
  }
}

export default Contact