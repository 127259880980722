import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import UserLogin from '../components/common/UserLogin'
 class UserLoginPage extends Component {
  render() {
    return (
      <Fragment>
         <Navbar/>
        <UserLogin/>
        <MainFooter/>    
      </Fragment>
    )
  }
}

export default UserLoginPage