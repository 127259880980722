import React, { Component, Fragment } from 'react'
import { Route,Switch } from 'react-router-dom/cjs/react-router-dom.min'
import HomePage from '../pages/HomePage'
import ContactPage from '../pages/ContactPage';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom';
import AboutPage from '../pages/AboutPage';
import PlanPage from '../pages/PlanPage';
import EventPage from '../pages/EventPage';
import EventDetails from '../pages/EventDetails';
import CoursePage from '../pages/CoursePage';
import CourseDetailPage from '../pages/CourseDetailPage';
import UserLoginPage from '../pages/UserLoginPage';
import RegisterPage from '../pages/RegisterPage';
import RefundPage from '../pages/RefundPage';


 class AppRoute extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
        <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/pricing" component={PlanPage} />
        <Route exact path="/event" component={EventPage} />
        <Route exact path="/event-details" component={EventDetails} />
        <Route exact path="/course" component={CoursePage} />
        <Route exact path="/course-details" component={CourseDetailPage} />
        <Route exact path="/sign-in" component={UserLoginPage} />
        <Route exact path="/sign-up" component={RegisterPage} />
        <Route exact path="/refund-policy" component={RefundPage} />
        </Switch>
        </BrowserRouter>
      </Fragment>
    )
  }
}

export default AppRoute