import React, { Component, Fragment } from 'react'

 class Course extends Component {
  render() {
    return (
      <Fragment>

    <div className="breadcrumb-area bg-overlay" style={{backgroundImage: 'url(assets/img/bg/3.png)'}}>
        <div className="container">
            <div className="breadcrumb-inner">
                <div className="section-title mb-0 text-center">
                    <h2 className="page-title">Courses</h2>
                    <ul className="page-list">
                        <li><a href="index.html">Home</a></li>
                        <li>Courses</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="blog-area pd-top-120 pd-bottom-120">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 order-lg-12">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/1.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/1.png" alt="img"/></span>
                                            <span className="align-self-center">Nancy Reyes</span>
                                        </div>
                                        <h6><a href="course-details.html">Fox nymphs grab quick-jived waltz. Brick quiz
                                                whangs</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/2.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/2.png" alt="img"/></span>
                                            <span className="align-self-center">Joe Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Aenean sed nibh a magna posuere tempo
                                                faucib</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/3.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/3.png" alt="img"/></span>
                                            <span className="align-self-center">Timothy Willis</span>
                                        </div>
                                        <h6><a href="course-details.html">Praesent eu dolor eu orci vehicula euismod</a>
                                        </h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.9
                                                    <span>(73)</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="price text-right">
                                                    Price: <span>$74.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/4.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/4.png" alt="img"/></span>
                                            <span className="align-self-center">Walter Griffin</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis vestibulum elit vel neque pharetra
                                                vulputate</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.8
                                                    <span>(53)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$64.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/5.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/5.png" alt="img"/></span>
                                            <span className="align-self-center">Aaron Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Quisque suscipit ipsum est, eu venenatis leo
                                                ornare eget</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.5
                                                    <span>(21)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$34.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/6.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/6.png" alt="img"/></span>
                                            <span className="align-self-center">Randy Hart</span>
                                        </div>
                                        <h6><a href="course-details.html">Duis rhoncus dui venenatis consequat
                                                porttito</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.4
                                                    <span>(20)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$55.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/7.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/2.png" alt="img"/></span>
                                            <span className="align-self-center">Joe Powell</span>
                                        </div>
                                        <h6><a href="course-details.html">Aenean sed nibh a magna posuere tempo
                                                faucib</a></h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.3
                                                    <span>(23)</span>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price text-right">
                                                    Price: <span>$54.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="single-course-inner">
                                <div className="thumb">
                                    <img src="assets/img/course/8.png" alt="img"/>
                                </div>
                                <div className="details">
                                    <div className="details-inner">
                                        <div className="emt-user">
                                            <span className="u-thumb"><img src="assets/img/author/3.png" alt="img"/></span>
                                            <span className="align-self-center">Timothy Willis</span>
                                        </div>
                                        <h6><a href="course-details.html">Praesent eu dolor eu orci vehicula euismod</a>
                                        </h6>
                                    </div>
                                    <div className="emt-course-meta">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="rating">
                                                    <i className="fa fa-star"></i> 4.9
                                                    <span>(73)</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="price text-right">
                                                    Price: <span>$74.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="td-page-navigation">
                        <ul className="pagination">
                            <li className="pagination-arrow"><a href="#"><i className="fa fa-angle-double-left"></i></a></li>
                            <li><a href="#">1</a></li>
                            <li><a className="active" href="#">2</a></li>
                            <li><a href="#">...</a></li>
                            <li><a href="#">3</a></li>
                            <li className="pagination-arrow"><a href="#"><i className="fa fa-angle-double-right"></i></a></li>
                        </ul>
                    </nav>
                </div>
                <div className="col-lg-4 order-lg-1 col-12">
                    <div className="td-sidebar mt-5 mt-lg-0">
                        <div className="widget widget_search_course">
                            <h4 className="widget-title">Search</h4>
                            <form className="search-form single-input-inner">
                                <input type="text" placeholder="Search here"/>
                                <button className="btn btn-base w-100 mt-3" type="submit"><i className="fa fa-search"></i>
                                    SEARCH</button>
                            </form>
                        </div>
                        <div className="widget widget_catagory">
                            <h4 className="widget-title">Catagory</h4>
                            <ul className="catagory-items">
                                <li><a href="#">Tempor lorem interdum <i className="fa fa-caret-right"></i></a></li>
                                <li><a href="#">Auctor mattis lacus <i className="fa fa-caret-right"></i></a></li>
                                <li><a href="#">Dolor proin <i className="fa fa-caret-right"></i></a></li>
                                <li><a href="#">Pharetra amet <i className="fa fa-caret-right"></i></a></li>
                            </ul>
                        </div>
                        <div className="widget widget_checkbox_list">
                            <h4 className="widget-title">Price</h4>
                            <label className="single-checkbox">
                                <input type="checkbox" checked="checked"/>
                                <span className="checkmark"></span>
                                Free Courses
                            </label>
                            <label className="single-checkbox">
                                <input type="checkbox"/>
                                <span className="checkmark"></span>
                                Paid Courses
                            </label>
                            <label className="single-checkbox">
                                <input type="checkbox"/>
                                <span className="checkmark"></span>
                                Only Subscription
                            </label>
                        </div>
                        <div className="widget widget_checkbox_list">
                            <h4 className="widget-title">Level</h4>
                            <label className="single-checkbox">
                                <input type="checkbox" checked="checked"/>
                                <span className="checkmark"></span>
                                Beginner
                            </label>
                            <label className="single-checkbox">
                                <input type="checkbox"/>
                                <span className="checkmark"></span>
                                Intermediate
                            </label>
                            <label className="single-checkbox">
                                <input type="checkbox"/>
                                <span className="checkmark"></span>
                                Advanced
                            </label>
                        </div>
                        <div className="widget widget_tags mb-0">
                            <h4 className="widget-title">Tags</h4>
                            <div className="tagcloud">
                                <a href="#">Art</a>
                                <a href="#">Creative</a>
                                <a href="#">Article</a>
                                <a href="#">Designer</a>
                                <a href="#">Portfolio</a>
                                <a href="#">Project</a>
                                <a href="#">Personal</a>
                                <a href="#">Landing</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </Fragment>
    )
  }
}

export default Course