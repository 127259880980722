import React, { Component, Fragment } from 'react'
import Navbar from '../components/global-components/navbar'
import MainFooter from '../components/global-components/MainFooter'
import EventDetail from '../components/others/EventDetail'

 class EventDetails extends Component {
  render() {
    return (
        <Fragment>
        <Navbar/>
        <EventDetail/>
        <MainFooter/>
      </Fragment>
    )
  }
}

export default EventDetails